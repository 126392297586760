body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.title {
  color: rgba(0, 0, 0, 0.87);
  height: 56px;
  line-height: 56px;
  overflow: hidden;
  opacity: 1;
  position: relative;
  padding-left: 0px;
  padding-right: 10px;
  text-overflow: ellipsis;
  top: 6px;
  white-space: nowrap;
  display: inline-block;
}

.filterField {
  vertical-align: bottom;
}

.filterTrash {
  vertical-align: middle;
}

/*# sourceMappingURL=DynamicFilter.cssmodule.css.map */

.header {
  cursor: pointer;
  vertical-align: middle;
  color: #fff;
}
.header:hover .icon {
  opacity: 0.3;
}
.header.active .icon {
  opacity: 1;
}
.header .text {
  height: 24px;
  line-height: 24px;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
}
.header .icon {
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  opacity: 0;
  color: #fff;
  transition: opacity 0.3s;
}

.activeRowColumn path {
  color: #fff;
  stroke: #fff;
  fill: #fff;
}

/*# sourceMappingURL=DynamicList.cssmodule.css.map */

/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/montserrat-v14-latin-regular.5cc74ef8.eot); /* IE9 Compat Modes */
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url(/static/media/montserrat-v14-latin-regular.5cc74ef8.eot?#iefix) format("embedded-opentype"), url(/static/media/montserrat-v14-latin-regular.bc3aa95d.woff2) format("woff2"), url(/static/media/montserrat-v14-latin-regular.8102c483.woff) format("woff"), url(/static/media/montserrat-v14-latin-regular.6a9e85ac.ttf) format("truetype"), url(/static/media/montserrat-v14-latin-regular.f3fef7e5.svg#Montserrat) format("svg"); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url(/static/media/montserrat-v14-latin-700.affb7169.eot); /* IE9 Compat Modes */
  src: local("Montserrat Bold"), local("Montserrat-Bold"), url(/static/media/montserrat-v14-latin-700.affb7169.eot?#iefix) format("embedded-opentype"), url(/static/media/montserrat-v14-latin-700.39d93cf6.woff2) format("woff2"), url(/static/media/montserrat-v14-latin-700.80f10bd3.woff) format("woff"), url(/static/media/montserrat-v14-latin-700.7432889d.ttf) format("truetype"), url(/static/media/montserrat-v14-latin-700.f9c15375.svg#Montserrat) format("svg"); /* Legacy iOS */
}
body {
  height: 100vh;
  margin: 0;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-position: 0 0;
  background-repeat: repeat;
}

body {
  display: flex;
  flex-direction: column;
}

#root > div {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/*# sourceMappingURL=App.css.map */

